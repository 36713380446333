import React, { useCallback, useState } from "react"
import {
  Box,
  Container,
  createStyles,
  Flex,
  SimpleGrid,
  Text,
  UnstyledButton,
} from "@mantine/core"
import { team } from "../../shared/utils/constants"
import { ArticleCardImage } from "./ArticleCardImage"
import { Carousel } from "@mantine/carousel"
import { Image } from "../../shared/ui"
import { EmblaCarouselType } from "embla-carousel"
import { IconArrowLeft, IconArrowRight } from "@tabler/icons-react"
import { useMediaQuery } from "@mantine/hooks"
import { AppTitle } from "../AppTitle"

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: "relative",
    backgroundImage: "url(assets/team.png)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",

    paddingTop: `calc(${theme.spacing.xl} * 4)`,
    paddingBottom: `calc(${theme.spacing.xl} * 4)`,

    [theme.fn.smallerThan("md")]: {
      paddingTop: `calc(${theme.spacing.xl} * 2)`,
      paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    },

    [theme.fn.smallerThan("xs")]: {
      paddingTop: `calc(${theme.spacing.xl} * 1.5)`,
      paddingBottom: `calc(${theme.spacing.xl} * 1.5)`,
    },
  },

  innerTitle: {
    marginBottom: 24,
    fontSize: 24,
    fontWeight: 500,

    [theme.fn.smallerThan("md")]: {
      fontSize: 20,
    },

    [theme.fn.smallerThan("sm")]: {
      fontSize: 18,
    },

    [theme.fn.smallerThan("xs")]: {
      fontSize: 16,
    },

    [theme.fn.largerThan(1024)]: {
      display: "none",
    },
  },

  teamControls: {
    display: "flex",
    marginBottom: 12,

    [theme.fn.largerThan(1024)]: {
      display: "none",
    },
  },

  previewContainer: {
    display: "flex",

    [theme.fn.smallerThan(1024)]: {
      display: "none",
    },
  },

  teamControlsBtn: {
    width: "50%",
    padding: "12px 16px",
    opacity: 0.3,
    transition: "all 0.3s",

    "&:hover": {
      opacity: 1,
    },

    [theme.fn.smallerThan(860)]: {
      padding: 8,
    },

    [theme.fn.smallerThan(600)]: {
      padding: 4,
    },
  },

  textBox: {
    position: "relative",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textWrap: "nowrap",
  },

  teamControlsName: {
    fontWeight: 700,
    fontSize: 24,

    [theme.fn.smallerThan(860)]: {
      fontSize: 20,
    },

    [theme.fn.smallerThan(768)]: {
      fontSize: 18,
    },

    [theme.fn.smallerThan(600)]: {
      fontSize: 16,
    },
  },

  teamControlsImage: {
    width: 64,
    height: 64,
    minWidth: 64,
    minHeight: 64,

    [theme.fn.smallerThan(600)]: {
      width: 48,
      height: 48,
      minWidth: 48,
      minHeight: 48,
    },
  },

  teamControlsBtnContainer: {
    gap: 16,

    [theme.fn.smallerThan(600)]: {
      gap: 8,
    },
  },

  carusel: {
    ".mantine-Carousel-controls": {
      top: 380,

      [theme.fn.smallerThan(1024)]: {
        top: 268,
      },

      [theme.fn.smallerThan(860)]: {
        top: 228,
      },

      [theme.fn.smallerThan(768)]: {
        top: 491.5,
      },

      [theme.fn.smallerThan(512)]: {
        top: "calc(100vw - 32px + 16px - 4px)",
      },

      [theme.fn.smallerThan(321)]: {
        top: "calc(288px + 16px - 4px)",
      },

      ".mantine-Carousel-control": {
        boxShadow: "none",
        border: "none",
        color: "#fff",
        background: theme.fn.linearGradient(45, theme.colors.blue[6], theme.colors.cyan[6]),
        borderRadius: 4,

        [theme.fn.smallerThan(1024)]: {
          opacity: 0.3,
        },
      },
    },
  },

  previewText: {
    textAlign: "center",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    marginTop: 12,
    minWidth: 115,

    [theme.fn.smallerThan(1240)]: {
      fontSize: 14,
    },
  },

  previewImage: {
    width: 64,
    height: 64,
    minWidth: 64,
    minHeight: 64,
    border: `3px solid ${theme.colors.blue[6]}`,

    [theme.fn.smallerThan(1240)]: {
      width: 56,
      height: 56,
      minWidth: 56,
      minHeight: 56,
      borderWidth: 2,
    },
  },

  previewBtn: {
    transition: "all 0.3s",
    opacity: 0.3,

    "&:hover": {
      opacity: 1,
    },
  },
}))

export function Team(): React.JSX.Element {
  const matched = useMediaQuery(`(max-width: 1024px)`)
  const { cx, classes } = useStyles()

  const [ slideIndex, setSlideIndex ] = useState<number>(team.length - 1)
  const [ emblaApi, setEmbaApi ] = useState<null | EmblaCarouselType>(null)

  const prevIndex = slideIndex - 1 < 0 ? team.length - 1 : slideIndex - 1
  const nextIndex = slideIndex + 1 > team.length - 1 ? 0 : slideIndex + 1

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev()
  }, [ emblaApi ])

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext()
  }, [ emblaApi ])

  const scrollTo = (index: number) => () => emblaApi && emblaApi.scrollTo(index)
  const onChange = (index: number) => setSlideIndex(index)

  return (
    <section id="team" className={classes.wrapper}>

      <Container size={1320}>
        <AppTitle preTitle="Преподавательский состав"/>

        <Flex className={classes.previewContainer} direction="column" align="center" justify="center" mb={64} mx="auto">
          <SimpleGrid verticalSpacing={24} spacing={12} cols={7}>
            {team.map((unit, index) => (
              <UnstyledButton
                key={unit.value}
                onClick={scrollTo(index)}
                className={cx({ [classes.previewBtn]: slideIndex !== index })}
              >
                <Flex direction="column" align="center" justify="center">
                  <Image
                    sx={{ borderRadius: 4 }}
                    className={classes.previewImage}
                    src={unit.small}
                  />
                  <div className={classes.previewText}>{unit.name.replace(" ", "\n")}</div>

                </Flex>
              </UnstyledButton>
            ))}
          </SimpleGrid>
        </Flex>

        <Text align="center" className={classes.innerTitle}>{`Для вас работают ${team.length} профессионалов`}</Text>

        <Flex className={classes.teamControls}>
          <UnstyledButton onClick={scrollPrev} className={classes.teamControlsBtn}>
            <Flex justify="flex-end" className={classes.teamControlsBtnContainer}>
              <Box className={classes.textBox}>
                <Text truncate align="right" className={classes.teamControlsName}>{team[prevIndex].name}</Text>
                <Text maw={360} truncate size="xs" color="dimmed" align="right">{team[prevIndex].description}</Text>
              </Box>
              <Image
                sx={{ borderRadius: 4 }}
                className={classes.teamControlsImage}
                src={team[prevIndex].small}
              />
            </Flex>
          </UnstyledButton>

          <UnstyledButton onClick={scrollNext} className={classes.teamControlsBtn}>
            <Flex className={classes.teamControlsBtnContainer}>
              <Image
                sx={{ borderRadius: 4 }}
                className={classes.teamControlsImage}
                src={team[nextIndex].small}
              />
              <Box className={classes.textBox}>
                <Text truncate className={classes.teamControlsName}>{team[nextIndex].name}</Text>
                <Text maw={360} truncate size="xs" color="dimmed">{team[nextIndex].description}</Text>
              </Box>
            </Flex>
          </UnstyledButton>

        </Flex>

        <Carousel
          className={classes.carusel}
          onSlideChange={onChange}
          withControls={true}
          slideSize="100%"
          slideGap="xl"
          loop
          align="start"
          controlSize={matched ? 40 : 48}
          controlsOffset={0}
          getEmblaApi={(embla) => {
            setEmbaApi(embla)
          }}
          previousControlIcon={<IconArrowLeft/>}
          nextControlIcon={<IconArrowRight/>}
        >
          {team.map((unit) => (
            <Carousel.Slide key={unit.value}>
              <ArticleCardImage {...unit}/>
            </Carousel.Slide>
          ))}
        </Carousel>

      </Container>
    </section>
  )
}
