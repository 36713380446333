export const menuItems = [
  { href: "program", label: "Программа" },
  { href: "team", label: "Преподаватели" },
  { href: "prices", label: "Стоимость" },
  { href: "license", label: "Образовательная лицензия" },
]

export interface TeamUnit {
  name: string
  description: string
  value: string
  cover: string
  small: string
  texts: Array<string>
  links?: {
    vk?: string
    instagram?: string
    telegram?: string
  }
}

export const team: Array<TeamUnit> = [
  {
    name: "Владислав Николайко",
    description: "Фитнес-эксперт",
    value: "nikolaiko",
    cover: "assets/team/nikolaiko/nikolaiko-480.jpg",
    small: "assets/team/nikolaiko/nikolaiko-64.jpg",
    texts: [
      "Мастер спорта России по бодибилдингу и пауэрлифтингу IPF, чемпион Европы по пауэрлифтингу IPF. Бронзовый призёр кубка Восточной Европы по бодибилдингу. 20-кратный Чемпион Краснодарского края по пауэрлифтингу и жиму лежа",
      "Действующий судья Федерации бодибилдинга России",
      "Международная сертификация тренера по фитнесу \"EuropeActive\"",
      "МГУ им. Ломоносова \"Физиология центральной нервной системы\"",
      "Автор книги \"PROкардио\"",
      "Лектор \"Чемпионских сборов\" команд по бодибилдингу и фитнесу, семинары для повышения квалификации тренерского состава",
    ],
    links: {
      instagram: "https://instagram.com/i_am_vlad_nikolaiko",
    },
  },
  {
    name: "Александр Бурлаков",
    description: "Врач-диетолог доказательной медицины",
    value: "burlakov",
    cover: "assets/team/burlakov/burlakov-480.jpg",
    small: "assets/team/burlakov/burlakov-64.jpg",
    texts: [
      "Опыт работы более 9 лет",
      "Специалист по диетологическому сопровождению спортсменов UFC, ACA, RCC",
      "Автор книги: \"В гармонии с едой\"",
      "Приглашенный эксперт для проверки постерных докладов студентов и молодых ученых, медицинских конференций (РНИМУ им. Пирогова)",
      "Сертификационные курсы: разработка и интерпретация клинических исследований (Johns Hopkins University), организация хранения и обработки данных для клинических исследований (Vanderbilt University), эпидемиология: фундаментальная наука о здоровье населения (University of North Carolina at Chapel Hill)",
      "Обучил более 5000 студентов",
    ],
    links: {
      vk: "https://vk.com/dr.burlakov",
      instagram: "https://instagram.com/dr.burlakov",
    },
  },
  {
    name: "Вадим Гурьев",
    description: "Сертифицированный тренер",
    value: "gurev",
    cover: "assets/team/gurev/gurev-480.jpg",
    small: "assets/team/gurev/gurev-64.jpg",
    texts: [
      "Сертифицированный инструктор тренажерного зала, персональный тренер с опытом работы более 10 лет",
      "Сертифицированный тренер: по стретчингу, по функциональному и кондиционному тренингу, по реабилитационным программам в фитнесе",
      "Методист Encore Fitness: координация деятельности тренерского состава, обеспечения повышения квалификации тренеров, организация и проведение практических семинаров, теоретических лекций",
      "Презентер всероссийских, международных конвенций и форумов по фитнесу",
    ],
    links: {
      telegram: "https://t.me/GURU_DIARY",
    },
  },
  {
    name: "Антонина Андрющенко",
    description: "Старший преподаватель Body Coach",
    value: "andruschenko",
    cover: "assets/team/andruschenko/andruschenko-480.jpg",
    small: "assets/team/andruschenko/andruschenko-64.jpg",
    texts: [
      "Магистр в области физической культуры и спорта",
      "КМС по художественной гимнастике",
      "Тренер-методист",
    ],
    links: {
      instagram: "https://instagram.com/_antonina_article",
    },
  },
  {
    name: "Валерия Федоровских",
    description: "Врач-педиатр",
    value: "fedorovskih",
    cover: "assets/team/fedorovskih/fedorovskih-480.jpg",
    small: "assets/team/fedorovskih/fedorovskih-64.jpg",
    texts: [
      "Закончила педиатрический факультет ТюмГМУ с отличием",
      "Закончила ординатуру по специальности \"Педиатрия\"",
      "Преподаватель биологической химии студентам медицинских ВУЗов",
    ],
    links: {
      instagram: "https://instagram.com/valeruna_",
    },
  },
  {
    name: "Иван Кузнецов",
    description: "Врач-педиатр",
    value: "kuznetsov",
    cover: "assets/team/kuznetsov/kuznetsov-480.jpg",
    small: "assets/team/kuznetsov/kuznetsov-64.jpg",
    texts: [
      "Закончил педиатрический факультет ТюмГМУ с отличием",
      "Закончил ординатуру по специальности \"Педиатрия\"",
      "2 года работал в \"красной зоне\" стационара",
      "Преподаватель патологической физиологии студентам медицинских ВУЗов",
    ],
    links: {
      instagram: "https://instagram.com/doc.spartakovich",
    },
  },
  {
    name: "Иван Андрейко",
    description: "Врач-лфк и спортивной медицины",
    value: "andreyko",
    cover: "assets/team/andreyko/andreyko-480.jpg",
    small: "assets/team/andreyko/andreyko-64.jpg",
    texts: [
      "Специалист в организации здравоохранения и общественного здоровья",
      "Опыт преподавательской деятельности с 2001 года",
      "Преподаватель в медицинском университете",
      "Тренер-преподаватель",
    ],
  },
  {
    name: "Алексей Варфоломеев",
    description: "Специалист по модификации образа жизни",
    value: "varfolomeev",
    cover: "assets/team/varfolomeev/varfolomeev-480.jpg",
    small: "assets/team/varfolomeev/varfolomeev-64.jpg",
    texts: [
      "Нутрициолог",
      "Судья международного класса по вейкборду IWWF/CWWC",
    ],
    links: {
      instagram: "https://instagram.com/varfolomeev.fit",
    },
  },
  {
    name: "Анастасия Юрпалова",
    description: "Нутрициолог, фитнес-тренер",
    value: "urpalova",
    cover: "assets/team/urpalova/urpalova-480.jpg",
    small: "assets/team/urpalova/urpalova-64.jpg",
    texts: [
      "Курс Лайна Нортона \"The Science of Nutrition\", Clean Health Fitness Institute",
      "\"Fat Loss Fundamentals\", Clean Health Fitness Institute",
      "\"Managing Your Health: The Role of Physical Therapy and Exercise\", University of Toronto",
      "\"Stanford Introduction to Food and Health\", Stanford University",
      "\"Cooking for Busy Healthy People\", Stanford University",
      "Rebuilding Our Relationship with Food\", Stanford University",
      "Куратор образовательно-тренировочных курсов Дмитрия Путылина Хардкор, Хардкор-Реверс, Форма",
      "Сотрудничала в качестве эксперта-редактора разделов Фитнес, Здоровье и Еда, с журналом Men Today",
      "Автор серии статей о фитнес индустрии для Men Today",
    ],
    links: {
      instagram: "https://instagram.com/nastya.yurp",
    },
  },
  {
    name: "Александр Комелин",
    description: "Фитнес-эксперт",
    value: "komelin",
    cover: "assets/team/komelin/komelin-480.jpg",
    small: "assets/team/komelin/komelin-64.jpg",
    texts: [
      "Директор и создатель фитнес клуба Эйфория",
      "Наставник тренерского состава",
      "Nutrition coach by Precision nutrition",
    ],
    links: {
      instagram: "https://instagram.com/alex_komelin",
    },
  },
  {
    name: "Вадим Луханин",
    description: "Наставник фитнес-тренеров",
    value: "lukhanin",
    cover: "assets/team/lukhanin/lukhanin-480.jpg",
    small: "assets/team/lukhanin/lukhanin-64.jpg",
    texts: [
      "Обучение тренеров выходу в онлайн, велению блога, привлечения клиентов",
      "300+ тренеров вывел в онлайн на средний доход 100к+/мес",
      "Более 10 лет сам был фитнес-тренером",
    ],
    links: {
      instagram: "https://instagram.com/vadimlukhanin",
    },
  },
  {
    name: "Даниил Рабаданов",
    description: "Врач анестезиолог-реаниматолог",
    value: "rabadanov",
    cover: "assets/team/rabadanov/rabadanov-480.jpg",
    small: "assets/team/rabadanov/rabadanov-64.jpg",
    texts: [
      "Врач скорой медицинской помощи",
      "Врач анестезиолог-реаниматолог",
      "Ассистент кафедры госпитальной терапии",
      "Высшее медицинское образование",
    ],
    links: {
      instagram: "https://instagram.com/doc.rabadanov",
    },
  },
  {
    name: "Юлия Саакова",
    description: "Сертифицированный тренер",
    value: "saakova",
    cover: "assets/team/saakova/saakova-480.jpg",
    small: "assets/team/saakova/saakova-64.jpg",
    texts: [
      "Совладелец студии YUNA pilates place",
      "Сертифицированный тренер: TRX, Synergy360,Terragun, Les Mills (Pump, Body balance, CXWorx, Shbam), Fitlab, FISAF, StickMobility, Polestar Pilates",
    ],
    links: {
      instagram: "https://instagram.com/yuliandrrr",
    },
  },
  {
    name: "Евгений Пресняков",
    description: "Фитнес-эксперт",
    value: "presnyakov",
    cover: "assets/team/presnyakov/presnyakov-480.jpg",
    small: "assets/team/presnyakov/presnyakov-64.jpg",
    texts: [
      "Управляющий фитнес центром \"50 GYM\"",
      "Управляющий партнер фитнес клуба \"Vault\"",
      "Партнер компании \"TRINET\"",
    ],
  },
  {
    name: "Анна Подалюк",
    description: "Персональный тренер",
    value: "podaluk",
    cover: "assets/team/podaluk/podaluk-480.jpg",
    small: "assets/team/podaluk/podaluk-64.jpg",
    texts: [
      "Персональный тренер с опытом более 20 лет",
      "Специалист по адаптивной физической культуре",
      "Организатор научно-популярного проекта \"Фитнес против боли\" и управляющая одноимённой студией в Екатеринбурге",
    ],
  },
]
