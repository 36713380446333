import { Text, Container, Box, createStyles, Flex, Grid, Title, Anchor, Group, ActionIcon } from "@mantine/core"
import { IconBrandVk, IconBrandYoutube, IconBrandTelegram, IconBrandWhatsapp } from "@tabler/icons-react"
import { VkGroupWidget } from "../vkGroup"
import React from "react"
// import { Oferta } from "./Oferta"
import { PrivacyModalStore } from "../../app/PrivacyModalStore"
import { Circle } from "../Circle"

// ----------------------------------------------------------------------

const useStyles = createStyles((theme) => ({
  footer: {
    minWidth: 320,
    paddingBottom: theme.spacing.xl,
    overflow: "hidden",
    position: "relative",

    "&::before": {
      content: "''",
      position: "absolute",
      top: -1,
      bottom: 0,
      left: 0,
      right: "-18%",
      backgroundImage: "url(assets/footer.png)",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
      zIndex: -1,
    },

  },

  container: {
    paddingTop: `calc(${theme.spacing.xl} * 4)`,
    paddingBottom: `calc(${theme.spacing.xl} * 1.5)`,

    [theme.fn.smallerThan("md")]: {
      paddingTop: `calc(${theme.spacing.xl} * 2)`,
    },

    [theme.fn.smallerThan("xs")]: {
      paddingTop: `calc(${theme.spacing.xl} * 1.5)`,
    },
  },

  afterFooter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  title: {
    textAlign: "center",
    paddingBottom: theme.spacing.lg,
    marginBottom: theme.spacing.lg,

    [theme.fn.smallerThan("md")]: {
      paddingBottom: 0,
    },

    [theme.fn.smallerThan("xs")]: {
      fontSize: 24,
    },
  },
}))

export function Footer(): React.JSX.Element {
  const { classes } = useStyles()
  const open = () => PrivacyModalStore.setIsOpened(true)

  return (
    <footer className={classes.footer}>

      <Circle styles={
        {
          right: "-20%",
          bottom: "-50%",
          width: "calc(100vw * 0.4)",
          height: "calc(100vw * 0.4)",
        }
      }/>
      <Circle styles={
        {
          left: "-20%",
          top: "-45%",
          width: "calc(100vw * 0.4)",
          height: "calc(100vw * 0.4)",
          opacity: 0.7,
        }
      }/>

      <Container className={classes.container} size="xl">
        <Grid justify="center">
          <Grid.Col sm={6} lg={4}>
            <Title size="h2" className={classes.title}>
              Документы
            </Title>

            <Flex direction="column">
              {/*<Oferta/>*/}
              <Anchor
                href="https://disk.yandex.ru/i/IYEPCsKwBQF_uA"
                target="_blank"
                color="dark"
                mb={8}
              >
                Договор об оказании<br/>платных образовательных услуг
              </Anchor>
              <Anchor color="dark" onClick={open}>
                Политика конфиденциальности
              </Anchor>

              <Box mt="xs" c="dimmed">
                <Text>ООО &#34;БОДИ КОУЧ&#34;</Text>
                <Text>ИНН: 2311299115</Text>
                <Text>ОГРН: 1192375085385</Text>
              </Box>

              <Text c="dimmed" mt="xs">
                № Образовательной лицензии:
                <br/>
                Л035-01218-23/00592537
              </Text>
            </Flex>
          </Grid.Col>

          <Grid.Col sm={6} lg={4}>
            <Title size="h2" className={classes.title}>
              Контакты
            </Title>

            <Flex direction="column">
              <Anchor color="dark" href="mailto:info@bodycoach.pro">
                info@bodycoach.pro
              </Anchor>

              <Box mt="xs" c="dimmed">
                <Anchor color="dark" href="tel:+78005516627">
                  8 800 551-66-27
                </Anchor>
                <Text>(звонок бесплатный)</Text>
              </Box>

              <Anchor color="dark" href="tel:+89952048045" mt="xs">
                8 995 204-80-45
              </Anchor>

              <Box mt="xs" c="dimmed">
                <Text>Юридический адрес:</Text>
                <Text>350072, г. Краснодар, Краснодарский край, ул. Московская 118 корпус 1, помещ. 145,
                  147</Text>
              </Box>
            </Flex>
          </Grid.Col>

          <Grid.Col sm={6} lg={4}>
            <Title size="h2" className={classes.title}>
              Соцсети
            </Title>

            <Flex direction="column">
              <Group mt="md" noWrap position="center" mb="md">
                <ActionIcon
                  component="a"
                  href="https://vk.com/bodycoachschool"
                  target="_blank"
                  color="blue"
                  size="xl"
                  variant="filled"
                  radius={4}
                >
                  <IconBrandVk/>
                </ActionIcon>
                <ActionIcon
                  component="a"
                  href="https://www.youtube.com/channel/UCVDLKEfCv2qrwf8q1R-3dKA"
                  target="_blank"
                  color="red"
                  size="xl"
                  variant="filled"
                  radius={4}
                >
                  <IconBrandYoutube/>
                </ActionIcon>
                <ActionIcon
                  component="a"
                  href="https://t.me/bodycoach_school"
                  target="_blank"
                  color="cyan"
                  size="xl"
                  variant="filled"
                  radius={4}
                >
                  <IconBrandTelegram/>
                </ActionIcon>
                <ActionIcon
                  component="a"
                  href="https://api.whatsapp.com/send/?phone=79952048045&text=ID%3A+QKNSad08wcc&type=phone_number&app_absent=0"
                  target="_blank"
                  color="green"
                  size="xl"
                  variant="filled"
                  radius={4}
                >
                  <IconBrandWhatsapp/>
                </ActionIcon>
              </Group>

              <VkGroupWidget/>
            </Flex>
          </Grid.Col>
        </Grid>
      </Container>

      <Container size="xl" className={classes.afterFooter} pt={24}>
        <Flex direction="column" gap="xs" align="center">
          <Text align="center" color="dimmed">
            Copyright&nbsp;&copy;&nbsp;{new Date().getFullYear()} Все&nbsp;права&nbsp;защищены
          </Text>
          <Text color="dimmed">BODYCOACH.PRO</Text>
        </Flex>
      </Container>
    </footer>
  )
}
